<template>
  <div class="text-center">
    <v-form v-model="valid" ref="form" @submit.prevent="handleFormSubmit">

      <Label :is-body-color="true" class="text-left">Password</Label>
      <v-text-field
          ref="password"
          key="password"
          v-model.trim="password"
          solo
          outlined
          dense
          flat
          required
          :rules="passwordRules"
          hide-details="auto"
          class="mb-5 font-size-15"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          :error-messages="errors.oldPassword"
          @input="resetFormValidation"
      ></v-text-field>

      <Label :is-body-color="true" class="text-left">New password</Label>
      <v-text-field
          ref="newPassword"
          key="newPassword"
          v-model.trim="newPassword"
          solo
          outlined
          dense
          flat
          required
          :rules="passwordRules"
          hide-details="auto"
          class="mb-5 font-size-15"
          :type="showNewPassword ? 'text' : 'password'"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showNewPassword = !showNewPassword"
          :error-messages="errors.newPassword"
          @input="resetFormValidation"
      ></v-text-field>

      <Label :is-body-color="true" class="text-left">Confirm new password</Label>
      <v-text-field
          ref="confirmPassword"
          key="confirmPassword"
          v-model.trim="confirmPassword"
          solo
          outlined
          dense
          flat
          required
          :rules="passwordRules"
          hide-details="auto"
          class="mb-5 font-size-15"
          :type="showConfirmPassword ? 'text' : 'password'"
          :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showConfirmPassword = !showConfirmPassword"
          :error-messages="errors.confirmPassword || confirmPasswordError"
          @input="resetFormValidation"
      ></v-text-field>

      <v-btn type="submit" class="custom--btn" height="40" color="cyan accent-4 "  dark block depressed>Update Password</v-btn>
    </v-form>
  </div>
</template>

<script>
import authApi from '../api/auth';
import { emailRules, passwordRules, requiredRules } from '../js/validators';
import {mapMutations} from "vuex";
import {showNotification} from "../js/helpers";
import Label from "./Label";


export default {
  name: 'SettingsPassword',

  components: {
    Label
  },

  computed: {
    confirmPasswordError() {
      return this.confirmPassword.length && this.newPassword !== this.confirmPassword ? 'Password must match' : '';
    }
  },

  mounted() {
  },

  methods: {
    ...mapMutations(['setLoading']),
    resetFormValidation() {
      this.errors.oldPassword = '';
      this.errors.newPassword = '';
      this.errors.confirmPassword = '';
    },
    handleFormSubmit(e) {
      this.resetFormValidation();
      if (!this.$refs.form.validate() || !this.valid) { return }

      this.$nextTick(async function() {
        this.setLoading(true);

        const { status, message, fields = [] } = await authApi.changePassword.call(this,{
          oldPassword: this.password,
          newPassword: this.newPassword,
          confirmPassword: this.confirmPassword
        });

        this.setLoading(false);

        if (!status) { return; }

        showNotification({ title: 'Password was successfully changed' });

        this.$refs.form.resetValidation();

        this.$refs.password.blur();
        this.$refs.newPassword.blur();
        this.$refs.confirmPassword.blur();

        this.password = '';
        this.newPassword = '';
        this.confirmPassword = '';
      });
    }
  },

  data: () => ({
    valid: false,
    showPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,

    password: '',
    newPassword: '',
    confirmPassword: '',

    passwordRules: [...requiredRules, ...passwordRules],

    errors: { oldPassword: '', newPassword: '', confirmPassword: '' }
  }),
}
</script>

<style lang="scss">

</style>
