<template>
  <v-container class="px-8">
    <v-row align="center">
      <v-col>
        <h3>Settings</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-card-actions class="py-6 px-8">
            <div class="width-100">
              <Label :is-body-color="true">Email Address</Label>
              <v-text-field
                  :value="user.email"
                  solo
                  outlined
                  dense
                  flat
                  class="mb-2 font-size-15 input--readonly"
                  hide-details="auto"
                  readonly
              ></v-text-field>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-card-actions class="py-6 px-8">
            <div class="width-100">
              <Label :is-body-color="true">Your subscription ends on</Label>
              <v-text-field
                  :value="user.accessGrantedUntilFormatted || '-'"
                  solo
                  outlined
                  dense
                  flat
                  class="mb-2 font-size-15 input--readonly"
                  hide-details="auto"
                  readonly
              ></v-text-field>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card outlined>
          <v-card-actions class="py-6 px-8">
            <v-row>
              <v-col cols="12" sm="6" order="2" order-sm="1">
                <SettingsPassword />
              </v-col>
              <v-col cols="12" sm="6" order="1" order-sm="2">
                <div class="custom--border fill-height px-lg-12 px-md-8 px-sm-6 px-4 py-6 bg--gray d-flex align-center justify-center">
                  <div>
                    <p class="font-size-15">Password requirements</p>
                    <p class="text-body-2 text--disabled-input ">To create a new password, you have to meet all of the following requirements:</p>
                    <ul class="text-body-2 text--disabled-input ">
                      <li>Minimum 6 character</li>
                      <li>At least one number</li>
                      <li>Can’t be the same as a previous password</li>
                    </ul>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import { mapGetters, mapState, mapMutations } from 'vuex';
import {copyTextToClipboard, showNotification} from "../js/helpers";
import {requiredRules} from "../js/validators";
import $ from 'jquery';
import Label from '../components/Label';
import SettingsPassword from "../components/SettingsPassword";

export default {
  name: 'Settings',

  components: {
    Label, SettingsPassword
  },

  watch: {

  },

  computed: {
    ...mapState(['user'])
  },

  methods: {
    ...mapMutations(['setLoading']),

  },

  beforeMount() {
    this.requiredRules = requiredRules;
  },

  async mounted() {
  },

  data: () => ({

  }),
}
</script>

<style lang="scss">

</style>