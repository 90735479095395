<template>
  <label class="custom-label" :class="labelClass" :for="forInput">
    <slot></slot>
  </label>
</template>

<script>

export default {
  name: 'Label',

  computed: {
    labelClass() {
      return this.isBodyColor ? '' : 'text--secondary';
    }
  },

  props: [
    'forInput', 'isBodyColor'
  ],

  watch: {

  },

  created() {

  },

  mounted() {

  },

  methods: {

  },

  data: () => ({

  }),
}
</script>

<style lang="scss">
.custom-label {
  font-size: 13px;
  display: block;
  margin-bottom: 0.25rem;
}
</style>
